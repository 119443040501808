<template>
  <div>
    <div class='mb-6 flex flex-row items-center'>
      <h3 class='text-gray-700'>Grouping Field</h3>
    </div>
    <div class='' v-if='hasCustomFieldTemplates'>
      <div class='flex flex-col justify-start'>
        <select v-model='selectedTemplateId' class='form-select w-full'>
          <option v-for='template in selectableTemplates' :key='`grouping-field-${template.id}`' :value='template.id'>
            {{template.custom_field_name}}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'GroupingByCustomField',
  data () {
    return {
    }
  },
  computed: {
    ...mapState('customFields', [
      'customFieldTemplates',
    ]),
    ...mapState('dashboardViews', [
      'groupByCustomTemplateId',
      'mappingFieldTemplateIds',
    ]),
    selectedTemplateId: {
      get () {
        return this.groupByCustomTemplateId
      },
      set (templateId) {
        this.resetGroupingCustomField()
        this.updateGroupByCustomTemplateId(templateId)
      }
    },
    hasCustomFieldTemplates () {
      return this.customFieldTemplates.length > 0
    },
    hasSelectedTemplate () {
      return this.selectedTemplateId !== 0
    },
    selectableTemplates () {
      // TODO: figure out contacts, file
      let groupByField = []
      if (this.mappingFieldTemplateIds.length > 0) {
        groupByField = this.customFieldTemplates.filter(template => ['dropdown', 'checkbox', 'multi-select'].includes(template.field_type) && this.mappingFieldTemplateIds.includes(template.id))
      }
      let noGroup = {
        id: 0,
        custom_field_name: '그룹 없음'
      }
      groupByField.unshift(noGroup)
      return groupByField
    },
    selectedTemplate () {
      return this.customFieldTemplates.find(template => template.id === this.selectedTemplateId)
    },
    selectableTemplateValues () {
      return (this.selectedTemplate) ? this.selectedTemplate.selectable_values : []
    },
  },
  methods: {
    ...mapActions('customFields', [
      'getCustomFieldTemplates',
    ]),
    ...mapActions('dashboardViews', [
      'resetGroupingCustomField',
      'updateGroupByCustomTemplateId',
    ]),
  },
  mounted () {
    this.getCustomFieldTemplates()
  }
}
</script>
